
.configurator-modal {
	background-color: #000;
	color: #fff;
  font-size: 16px;
  text-align: center;
  font-family: 'Century Gothic', 'gothic', CenturyGothic, AppleGothic, sans-serif;
  
}

.configurator-modal .modal-footer{
  display:block;
  padding:0;
  border:none;
}

.configurator-modal .modal-header {
  width: 100%;
  height: 40px;
  background-color: #141414;
  top: 0;
  left: 0;
  text-align: right;
  z-index: 100;
  border:none;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
}

.configurator-modal .modal-header button {
   margin-right: 10%; 
   font-size: 14px;
   display: flex;
   align-items: center;
   text-transform: uppercase;
}

@media (max-width:1024px){
  .configurator-modal .modal-header button{
    margin-right: 20px; 
  }
}

.configurator-modal .modal-header button span{
  font-size: 35px;
  margin-left: 5px;
}
.configurator-modal .modal-body {
  height: calc(100vh - 40px);
}

@media (max-width:1024px){
  .configurator-modal .modal-body {
    padding: 0 20px;
  }
}

.configurator-modal.modal.show .modal-dialog {
  transform: none;
}

.configurator-modal .item-container .slick-track {
  min-width: auto;
}

.configurator-modal .slick-slider.item-container .slick-track {
  display: flex;
}

.configurator-modal .slick-slider.item-container .slick-slide {
  float: none; height: auto;
  position:relative;
}

.configurator-modal .slick-slider.item-container .slick-slide > div {
   height: 100%;
}



.configurator-modal .slick-arrow {
  border: 1px solid #966e55;
  opacity: 0.6;
  border-radius: 50%;
  width: 52px;
  height: 52px;
}


.configurator-modal .slick-arrow:hover {
    opacity: 1;
  
}

.configurator-modal .cta-back:hover {
  color: #FFF;
  cursor: pointer;
}

.configurator-modal .cta-back {
  text-decoration: none;
  position: relative;
  color: #FFF;
  display: flex;
  font-size: 14px;
  align-items: center;
}

@media (max-width:1024px){
  .configurator-modal .cta-back{
   font-size: 10px;
  }
}
.configurator-modal .cta-back::before{
    content: "<";
    display: block;
    font-weight: bold;
    border: 1px solid #966e55;
    height: 34px;
    width: 34px;
    border-radius: 50%;
    margin-right: 10px;
    color: #FFF;
    padding: 0;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

 

.configurator-modal u {
    text-decoration: none;
    color: #966e55;
}

.configurator-modal .slick-arrow {
  position: absolute;
}
.configurator-modal .slick-arrow.slick-prev{
  left: -80px;

}

.configurator-modal .slick-arrow.slick-next{
  right: -80px;
}

.configurator-modal .slick-prev::before{
    content: "<";
    display: block;
    border-radius: 50%;
    color: #FFF;
    font-family: monospace;
    font-size: 30px;

}
.configurator-modal .slick-next::before{
    content: ">";
    display: block;
    border-radius: 50%;
    color: #FFF;
    font-family: monospace;
    font-size: 30px;
}

.configurator-modal .slick-dots {
  left: 0;
  bottom: -25px;
}

.configurator-modal .slick-dots li{
  border: 1px solid #fff;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  margin: 0 10px;
  
}
.configurator-modal .slick-dots li.slick-active {
  background: #fff;
  
}



.configurator-modal .slick-dots li button{
  font-size: 12px;
  color: transparent;
  
}
.configurator-modal .slick-dots li button::before {
    opacity: 0 !important;
}
 
.configurator-modal .container-xxl {max-width:100%;}

.configurator-modal .box-product {
  
  overflow: hidden;
  transition: all 0.5s;
  
}

.configurator-modal.omni .item-wrap:hover {
  background: #966e55;

}

.configurator-modal.omni .item-wrap:hover u{
  color: #ffffff !important;
  
}

.configurator-modal.wave .item-wrap:hover {
  background: #b4a050;
}

.configurator-modal.steam .item-wrap:hover {
  background: #8296a0;
}

